import * as React from 'react'

import { GridContainer } from '@/components/Grid'

import { Container, LeftImageWrapper, RightImageWrapper, Wrapper, ImageLegend, ImageContent } from './style'
import { Image } from '@/components/FeaturedVideoBlock/style'
import resolveAssetURL from '@/utils/resolveAssetURL'
import PropTypes from 'prop-types'
import { CloseButton, OpenButton, Viewer, ViewerImage, ViewerWrapper } from '@/components/ImageTextPortrait/style'
import { useState } from 'react'

import closeButton from '@/images/close-button.svg'
import openButton from '@/images/open-button.svg'

const EditImages = ({ image1, alt1, caption1, image2, alt2, caption2, display }) => {
  // isOpen1 -> Control the viewer for the left image
  // isOpen2 -> Control the viewer for the right image
  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)

  return (
    <Container>
      <GridContainer>
        <Wrapper className={ display && 'alt' }>
          <Viewer isOpen={ isOpen1 }>
            <ViewerWrapper>
              <ViewerImage loading='lazy' src={ resolveAssetURL(image1) } alt={ alt1 } />
            </ViewerWrapper>
            <CloseButton onClick={ () => { setIsOpen1(false) } }>
              <Image src={ closeButton } alt='close icon' />
            </CloseButton>
          </Viewer>

          <Viewer isOpen={ isOpen2 }>
            <ViewerWrapper>
              <ViewerImage loading='lazy' src={ resolveAssetURL(image2) } alt={ alt2 } />
            </ViewerWrapper>
            <CloseButton onClick={ () => { setIsOpen2(false) } }>
              <Image src={ closeButton } alt='close icon' />
            </CloseButton>
          </Viewer>
          <LeftImageWrapper>
            <ImageContent>
              <Image loading='lazy' src={ resolveAssetURL(image1) } alt={ alt1 } />
              <OpenButton onClick={ () => { setIsOpen1(true) } }>
                <Image src={ openButton } alt='open icon' />
              </OpenButton>
            </ImageContent>
            <ImageLegend>
              { caption1 }
            </ImageLegend>
          </LeftImageWrapper>
          <RightImageWrapper>
            <ImageContent>
              <Image loading='lazy' src={ resolveAssetURL(image2) } alt={ alt2 } />
              <OpenButton onClick={ () => { setIsOpen2(true) } }>
                <Image src={ openButton } alt='open icon' />
              </OpenButton>
            </ImageContent>
            <ImageLegend>
              { caption2 }
            </ImageLegend>
          </RightImageWrapper>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

EditImages.propTypes = {
  image1: PropTypes.string,
  alt1: PropTypes.string,
  image2: PropTypes.string,
  alt2: PropTypes.string,
  display: PropTypes.bool
}

export default EditImages
